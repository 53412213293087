<template>
  <section class="container mt-5">
    <div class="d-flex">
      <ui-loader
        v-if="isLoadingCostAllocations"
        class="pt-5 center"
      />
      <div
        v-else
        class="d-flex flex-column"
      >
        <h2 class="mb-3">
          {{ $t('views.business_profile.cost_allocation_tab.label') }}
        </h2>
        <p class="emobg-font-medium">
          {{ $t('views.business_profile.cost_allocation_tab.text') }}
        </p>
        <ui-validate
          class="d-flex flex-column w-50"
          @status="({ detail }) => formStatus = detail"
        >
          <ui-text-input
            v-for="costAllocation in filter(currentProfileCostAllocations, ['inputType', INPUT_TYPES.text])"
            :key="costAllocation.name"
            v-validate.blurinput="costAllocation.validation"
            v-bind="costAllocation.props"
            :options.prop="costAllocation.options"
            :data-test-id="costAllocation.dataTestId"
            data-masked
            class="text-wrap py-2"
            @changevalue="({ detail }) => updateCostAllocationValue(detail, costAllocation.name)"
          />
          <ui-select
            v-for="costAllocation in filter(currentProfileCostAllocations, ['inputType', INPUT_TYPES.select])"
            :key="costAllocation.name"
            v-validate.select="costAllocation.validation"
            v-bind="costAllocation.props"
            :options.prop="costAllocation.options"
            :data-test-id="costAllocation.dataTestId"
            data-masked
            class="text-wrap py-2"
            @selectoption="({ detail }) => updateCostAllocationValue(detail, costAllocation.name)"
          />
          <ui-button
            v-bind="fetchButtonSpecs()"
            :disabled="!formStatus.isValid || !costAllocationsHadChanged"
            :loading="isProfileCostAllocationsLoading"
            data-test-id="save-button"
            class="my-4 w-10"
            @clickbutton="saveCostAllocations"
          >
            {{ $t('buttons.save') }}
          </ui-button>
        </ui-validate>
      </div>
    </div>
  </section>
</template>

<script>
import unionBy from 'lodash/unionBy';
import set from 'lodash/set';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import every from 'lodash/every';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';

import { Validate } from '@emobg/vue-base';
import { LOG_TYPE, logger } from '@emobg/web-utils';

import { getCurrentProfileCompanyUuid, getCurrentProfileUuid } from '@/stores/User/Profile/ProfileMapper';
import {
  fetchCompanyProfileCostAllocations,
  fetchProfileCostAllocation,
  getCompanyProfileCostAllocations,
  getProfileCostAllocation,
  isFetchCompanyProfileCostAllocationsLoading,
  isFetchProfileCostAllocationsLoading,
  isProfileCostAllocationsLoading,
  updateProfileCostAllocation,
} from '@/stores/CostAllocation/CostAllocationMapper';

import { parseApiErrorMessage } from '@/utils/apiHelpers';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import INPUT_TYPES from '@/constants/inputTypes';
import { useTheme } from '@/composable/Theme/useTheme';
import { generateSaveCostAllocationPayload, getCostAllocations } from './helpers/transformers';

export default {
  name: 'CostAllocationTab',

  directives: {
    Validate,
  },
  setup() {
    const { notifyError, notifySuccess } = useNotifications();
    const { fetchButtonSpecs } = useTheme();
    return {
      notifyError,
      notifySuccess,
      fetchButtonSpecs,
    };
  },
  data() {
    return {
      formStatus: { isValid: false },
      profileCostAllocationsData: [],
      previousProfileCostAllocations: [],
      currentProfileCostAllocations: [],
    };
  },
  computed: {
    getProfileCostAllocation,
    getCurrentProfileUuid,
    getCurrentProfileCompanyUuid,
    getCompanyProfileCostAllocations,
    isFetchCompanyProfileCostAllocationsLoading,
    isProfileCostAllocationsLoading,
    isFetchProfileCostAllocationsLoading,

    isLoadingCostAllocations() {
      return this.isFetchCompanyProfileCostAllocationsLoading || this.isFetchProfileCostAllocationsLoading;
    },
    costAllocationsHadChanged() {
      return !every(this.currentProfileCostAllocations, (costAllocation) => {
        const currentCostAllocationValue = get(costAllocation, 'props.value', '');
        const targetProp = get(costAllocation, 'inputType') === INPUT_TYPES.select ? 'uuid' : 'code';
        const savedCostAllocation = find(this.getProfileCostAllocation, ['uuid', get(costAllocation, 'uuid')]);

        return get(savedCostAllocation, `children.0.${targetProp}`, '') === currentCostAllocationValue;
      });
    },
  },

  async created() {
    this.INPUT_TYPES = INPUT_TYPES;
    try {
      await Promise.all([
        this.fetchProfileCostAllocation(this.getCurrentProfileUuid),
        this.fetchCompanyProfileCostAllocations(this.getCurrentProfileCompanyUuid),
      ]);

      this.profileCostAllocationsData = unionBy(
        this.getProfileCostAllocation,
        this.getCompanyProfileCostAllocations,
        'name',
      );
      this.currentProfileCostAllocations = getCostAllocations({
        costAllocations: this.profileCostAllocationsData,
        options: this.getCompanyProfileCostAllocations,
      });
      this.previousProfileCostAllocations = cloneDeep(this.currentProfileCostAllocations);
    } catch (error) {
      logger.message(error, LOG_TYPE.error);
      const errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
      this.notifyError({
        text: errorMessage,
      });
    }
  },

  methods: {
    filter,
    fetchProfileCostAllocation,
    fetchCompanyProfileCostAllocations,
    updateProfileCostAllocation,
    updateCostAllocationValue(newValue, name) {
      const costAllocationIndex = findIndex(this.currentProfileCostAllocations, ['name', name]);
      set(this.currentProfileCostAllocations, `${costAllocationIndex}.props.value`, newValue);
    },

    async saveCostAllocations() {
      const payload = {
        profileUuid: this.getCurrentProfileUuid,
        costAllocations: {
          cost_allocations: generateSaveCostAllocationPayload({ costAllocations: this.currentProfileCostAllocations }),
        },
      };

      try {
        await this.updateProfileCostAllocation(payload);
        this.notifySuccess({
          text: this.$t('modal.cost_allocation.success_notification_message'),
          textAction: this.$t('modal.common.ok'),
        });
      } catch (error) {
        logger.message(error, LOG_TYPE.error);
        const errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
        this.notifyError({
          text: errorMessage,
        });
      }
    },
  },
};

</script>
